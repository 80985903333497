<template>
  <v-card class="ma-2 pa-3 tom-card">
    <h3 class="main--text pb-1">{{ title }}</h3>

    <div v-for="vehicle in item.vehicles" :key="vehicle.id" class="d-flex justify-space-between align-center">
      <p class="my-1">{{ vehicle.code }} {{ vehicle.label }}</p>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'VehicleCard',

  props: {
    title: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    }
  }
}
</script>


<style>
.tom-card {
  font-size: 12px;
}
</style>
