<template>
  <div class="program-card">
    <div class="d-flex justify-space-between align-end pt-1 pb-1">
      <div style="height: 25px" class="d-flex">
        <!-- drag handler for copy -->
        <v-btn v-if="showDraggableSchedule()" icon class="pb-1" small>
          <v-icon>mdi-drag-vertical</v-icon>
        </v-btn>

        <!-- drag handler for sorting -->
        <v-btn v-else-if="!disable" icon class="handle-drag-plan pb-1" small>
          <v-icon>mdi-drag-vertical</v-icon>
        </v-btn>

        <div v-if="projectNames.length > 0" class="d-flex align-center ml-1" style="gap: 0.5em">
          <v-chip style="font-size: 1em;" v-for="project in projectNames" :key="project.id + '_header'" x-small>
            {{ project.name }}
          </v-chip>
        </div>
      </div>

      <p v-if="!disable" class="red--text hover ma-0 pa-0 mr-2" style="font-size: 11px" @click="$emit('delete-schedule', clientSchedule.id)">
        {{ translations.removeSchedule }}
      </p>
    </div>

    <div class="schedule-border">
      <div style="border-left: 4px solid #104272;" class="pt-2 pl-2 pr-2">
        <section class="d-flex justify-end blue--text">
          <v-btn @click="open = !open" depressed x-small>
            <v-icon v-if="open">{{ icons.mdiChevronUp }}</v-icon>
            <v-icon v-else>{{ icons.mdiChevronDown }}</v-icon>
          </v-btn>
        </section>
        <!-- Project add/remove with search. There can be multiple projects but they share drivers and operators -->
        <v-expand-transition>
          <div v-if="open">
            <div v-for="(project, i) in selectedProject.project.projects" :key="`${project.keyCode}__${project.id}__${i}`" class="project-schedule" style="margin-top: -5px; height: 85px">
              <section class="d-flex align-start">
                <!-- remove project -->
                <div v-if="!disable" class="mr-4">
                  <v-btn @click="removeProject(i, project)" color="red" x-small outlined fab style="width: 20px; height: 20px">
                    <v-icon>{{ icons.mdiMinus }}</v-icon>
                  </v-btn>
                </div>

                <!-- project dropdown with search autocomplete -->
                <aside class="project-schedule" :class="[project.name === '' ? 'mb-1' : '']">
                  <div class="d-flex align-center justify-space-between">
                    <label class="main--text label-style">{{ translations.project }}</label>

                    <label class="d-flex align-center" style="font-size: 12px">
                      <input type="radio" class="mr-2" :name="`mainProject_${clientSchedule.id}`" @change="setMainProject(project.id, project)" :checked="project.main" :disabled="disable" :value="project.id" />
                      {{ translations.mainProject }}
                    </label>
                  </div>

                  <!-- Menu with text field and lists of projects/categories divided in 2 sections -->
                  <v-menu offset-y allow-overflow height="32px" style="width:180px; font-size: 12px" :close-on-click="true" :close-on-content-click="true">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" class="f-12" height="32px" style="width:180px; font-size: 12px" outlined hide-details autocomplete="off" v-model="project.projectFilter" @input="projectFilterHandler"></v-text-field>
                    </template>
                    <div style="max-height: 300px" class="scroll-bar">
                      <div class="d-flex align-center px-2 pt-2" style="background-color: #fff">
                        <v-btn @click.stop="activeMenuList = 'projects'" x-small class="mr-2" :class="[activeMenuList === 'projects' ? 'white--text' : '']" :outlined="activeMenuList !== 'projects'" color="main">{{
                          translations.projects
                        }}</v-btn>
                        <v-btn @click.stop="activeMenuList = 'categories'" x-small :class="[activeMenuList === 'categories' ? 'white--text' : '']" :outlined="activeMenuList !== 'categories'" color="main">{{
                          translations.categories
                        }}</v-btn>
                      </div>
                      <v-list dense>
                        <template v-if="activeMenuList === 'projects'">
                          <v-list-item v-for="(filteredProject, filteredProjectIndex) in availableProjects" @click="changeProjectHandler(project, filteredProject.keyCode)" :key="`project-${filteredProject.id}_${filteredProjectIndex}`">
                            <v-list-item-content>
                              <v-list-item-title>{{ filteredProject.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ filteredProject.label }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!isLoadingProjects && hasNextProjects">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                        </template>

                        <template v-else-if="activeMenuList === 'categories'">
                          <v-list-item
                            v-for="(filteredProject, filteredProjectIndex) in availableCategoryProjects"
                            @click="changeProjectHandler(project, filteredProject.keyCode)"
                            :key="`category-${filteredProject.id}_${filteredProjectIndex}`"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ filteredProject.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ filteredProject.label }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="!isLoadingCategories && hasNextCategories">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
                </aside>

                <!-- part dropdown -->
                <v-slide-x-transition>
                  <aside class="ml-4 project-schedule" v-if="project.parts && project.parts.length > 0">
                    <div class="d-flex align-center justify-space-between">
                      <label class="main--text label-style">
                        {{ translations.part }}
                      </label>
                    </div>

                    <v-select
                      v-model="project.part"
                      clearable
                      item-text="keyCode"
                      item-value="id"
                      class="sg-select"
                      solo
                      hide-details
                      @change="partId => onProjectPartChange(project, partId)"
                      :items="project.parts"
                      :disabled="disable"
                      :deletable-chips="true"
                    >
                      <template v-slot:item="data">
                        <span>{{ `${data.item.keyCode} - ${data.item.description}` }}</span>
                      </template>
                    </v-select>
                  </aside>
                </v-slide-x-transition>

                <!-- Allowance field -->
                <v-slide-x-transition>
                  <aside class="ml-4 project-schedule">
                    <div class="d-flex align-center justify-space-between">
                      <label class="main--text label-style">{{ translations.allowance }}</label>
                    </div>
                    <v-text-field v-model="project.allowance" @change="newAllowance => onProjectAllowanceChange(project, newAllowance)" height="32px" style="width:60px; font-size: 12px" outlined hide-details :disabled="!project.projectId">
                    </v-text-field>
                  </aside>
                </v-slide-x-transition>
              </section>

              <!-- project description -->
              <div class="d-flex justify-space-between" :style="disable ? '' : ' padding-left: 40px'" v-if="project.label">
                <div class="d-flex flex-wrap" style="flex-direction: column; flex: 50%;">
                  <p class="pa-0 ma-0 py-1" :style="fontStyle">
                    {{ project.label }}
                  </p>
                </div>

                <!-- part description -->
                <div v-if="project.part" class="d-flex flex-wrap" style="flex-direction: column; flex: 50%;">
                  <p class="pa-0 ma-0 py-1 grey--text" style="font-size: 12px">
                    {{ partDescription(project.searchedProjects, project.projectId, project.part) }}
                  </p>
                </div>
              </div>
            </div>

            <!-- add project button when there are no projects. 
			NOTE 14 feb 2022: it is possible to add projects even if there is one already. Intended or bug? -->
            <div v-if="!disable" class="d-flex justify-start" style="margin-top: -20px">
              <v-btn @click="addProject" x-small outlined fab color="main" style="width: 20px; height: 20px">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </div>

            <!-- Descriptions -->
            <div style="display: none">
              <v-divider class="mt-3"></v-divider>

              <section class="project-schedule d-flex" style="width: 100%">
                <aside class="pa-1" style="width: 50%">
                  <label class="main--text label-style">{{ translations.bituminousConglomerate }}</label>
                  <v-text-field v-model="selectedProject.project.conGlomerateDesc" @change="onDescriptionChange" solo class="mt-1" name="input-7-4" hide-details no-resize :style="`font-size: 12px;`" :disabled="disable"></v-text-field>
                </aside>
                <aside class="project-schedule pa-1" style="width: 50%">
                  <label class="main--text label-style">{{ translations.quanityExpected }}</label>
                  <v-text-field v-model="selectedProject.project.quintalsExpectedDesc" solo class="mt-1" name="input-7-4" hide-details no-resize @change="onDescriptionChange" :style="`font-size: 12px;`" :disabled="disable"></v-text-field>
                </aside>
              </section>
            </div>
          </div>
        </v-expand-transition>
      </div>

      <!-- dinamyc table generation -->
      <section class="pa-1" v-for="teamType of teamTypes" :key="teamType.value">
        <project-schedule-table
          @on-change="data => onScheduleChange(data, true)"
          :title="translations[teamType.label]"
          :data="selectedProject.teams"
          :disable="disable"
          :scheduleType="teamType.value"
          :programId="clientSchedule.id"
          :copyMode="copyMode"
        ></project-schedule-table>
      </section>
    </div>
  </div>
</template>

<script>
/**
 * Project Schedule
 * This is a single schedule on the page.
 * Multiple Projects and Parts
 * It contains 2 tables:
 * The first table is the main schedule where the user assigns employees, suppliers, and vehicles
 * the second table is built the same way but more for notes for the user.
 *
 */
import ProjectScheduleTable from "@/components/ProjectScheduleTable.vue";
import { mdiChevronDown, mdiChevronUp, mdiPlus, mdiMinus } from "@mdi/js";
import addOrRemoveProjectMutation from "@/graphql/AddOrRemoveProject.gql";
import addOrRemovePartMutation from "@/graphql/AddOrRemovePart.gql";
import updateProgramDescMutation from "@/graphql/UpdateProgram.gql";
import updateProgramProjectMutation from "@/graphql/UpdateProgramProject.gql";
import updateProgramProjectAllowanceMutation from "@/graphql/UpdateProgramProjectAllowance.gql";
import setMainProjectMutation from "@/graphql/SetMainProject.gql";
import changeProgramProjectsOrderMutation from "@/graphql/ChangeProgramProjectsOrder.gql";
import { translations } from "@/utils/common";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import debounce from "lodash/debounce";
import { getDraggableSchedule, getUseBiggerFontInSchedule, getShowProjectDescriptionInSchedule, getCloseProgramHeader } from '@/utils/api/config';

export default {
  name: "ProjectSchedule",

  components: { ProjectScheduleTable },

  mixins: [MomentMixin],

  props: {
    teamTypes: {
      type: Array,
      default: () => []
    },
    clientSchedule: {
      type: Object,
      default: () => ({})
    },
    clients: {
      type: Array,
      default: () => []
    },
    availableProjects: {
      type: Array,
      default: () => []
    },
    availableCategoryProjects: {
      type: Array,
      default: () => []
    },
    disable: {
      type: Boolean,
      default: () => false
    },
    currentDate: {
      type: String,
      default: () => ""
    },
    copyMode: {
      type: Boolean,
      default: () => false
    },
    hasNextProjects: {
      type: Boolean,
      default: () => false
    },
    hasNextCategories: {
      type: Boolean,
      default: () => false
    },
    isLoadingProjects: {
      type: Boolean,
      default: () => false
    },
    isLoadingCategories: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    selectedProject: {
      id: null,
      names: [""],
      part: "",
      teams: []
    },
    open: true,
    namesAndDescription: [],
    projectToSearch: "",
    activeMenuList: "projects"
  }),

  async created() {
    const projectsLength = this.clientSchedule.project.projects.length;
    const projectLength = projectsLength > 0 ? Object.keys(this.clientSchedule.project.projects[0]).length : 0;

    if (projectsLength === 1 && projectLength <= 3) {
      const newProject = {
        projectId: "",
        name: "",
        part: "",
        main: true,
        projectFilter: "",
        projectSearchLoader: false,
        searchedProjects: []
      };

      let clientCopy = this.clientSchedule;

      clientCopy.project.projects = [];

      clientCopy.project.projects.push(newProject);

      this.selectedProject = Object.assign({}, clientCopy);
    } else {
      this.selectedProject = Object.assign({}, this.clientSchedule);
    }

    if (this.selectedProject.project.projects?.length === 0) {
      this.selectedProject.project.projects.push({
        projectId: "",
        name: "",
        part: "",
        main: true,
        projectFilter: "",
        searchedProjects: [],
        projectSearchLoader: false
      });
    } else {
      this.selectedProject.project.projects.forEach(async project => {
        project.searchedProjects = this.availableProjects;
        // project.parts = this.availableProjects.find(p => p.id == project.projectId)?.parts;
      });
    }

    if (this.disable || getCloseProgramHeader()) {
      this.open = false;
    }
  },

  watch: {
    selectedProject: {
      handler: function() {
        this.$emit("on-change", this.selectedProject);
        this.onProjectChange();
      },
      deep: true
    },
    availableProjects(val) {
      this.selectedProject.project.projects.forEach(project => {
        project.searchedProjects = val;
        project.parts = project?.parts ? project?.parts : val.find(p => p.id == project.projectId)?.parts;
      });
    }
  },

  methods: {
    showDraggableSchedule() {
      if (this.copyMode && getDraggableSchedule()) {
        const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
        return dateToCompare.dateCopy === this.currentDate
      }
      return false
    },

    // sets a project to be the main project
    async setMainProject(programProjectId, project) {
      if (project?.id) {
        this.selectedProject.project.projects.forEach(project => {
          project.main = false;
        });

        project.main = true;
        await this.$apollo
          .mutate({
            mutation: setMainProjectMutation,
            variables: { programProjectId }
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    },

    // Watches for changes in each table and emits to the parent
    onScheduleChange(changedTeams) {
      this.selectedProject.teams = changedTeams;
      this.$emit("on-change", this.selectedProject);
    },

    // Watches for a project change
    async onProjectNameChange(project) {
      // We check here if the project.id is not null because when a new project is added
      // the id will be set to null, so we know that this is not in the db.
      // Once it checks if its exists we can assume that we can update the project for this schedule
      if (project?.id) {
        await this.$apollo
          .mutate({
            mutation: updateProgramProjectMutation,
            variables: {
              programProject: {
                id: project.id,
                program: { id: this.clientSchedule.id },
                project: { id: project.projectId },
                main: project.main,
                allowance: project.allowance
              }
            }
          })
          .catch(error => {
            this.handleError(error);
          });
        project.part = null;
      } else {
        // If the project.id is null then we must create the project for this schedule
        project.part = null;
        const { data } = await this.$apollo
          .mutate({
            mutation: addOrRemoveProjectMutation,
            variables: {
              programProject: {
                program: { id: this.clientSchedule.id },
                project: { id: project.projectId },
                main: !project?.main ? false : project.main,
                allowance: project.allowance
              }
            }
          })
          .catch(error => {
            this.handleError(error);
          });

        const newProgramProject = data.addOrRemoveProject;
        project.id = newProgramProject?.id;
      }

      await this.updateProgramProjectsOrder();
    },

    async onProjectPartChange(project, partId) {
      await this.$apollo
        .mutate({
          mutation: addOrRemovePartMutation,
          variables: {
            partId: partId,
            programProjectId: project.id,
            programId: this.clientSchedule.id
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },

    async onProjectAllowanceChange(project, allowance) {
      await this.$apollo
        .mutate({
          mutation: updateProgramProjectAllowanceMutation,
          variables: {
            allowance: allowance,
            programProjectId: project.id,
            programId: this.clientSchedule.id
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },

    onProjectChange() {
      const projects = this.selectedProject.project.projects.filter(a => a?.projectId);
      this.$emit("on-project-change", {
        id: this.clientSchedule.id,
        projects,
        conGlomerateDesc: this.selectedProject.project.conGlomerateDesc,
        quintalsExpectedDesc: this.selectedProject.project.quintalsExpectedDesc
      });
    },

    async onDescriptionChange() {
      await this.$apollo
        .mutate({
          mutation: updateProgramDescMutation,
          variables: {
            programId: this.clientSchedule.id,
            asphaltDesc: this.selectedProject.project.conGlomerateDesc,
            quantityDesc: this.selectedProject.project.quintalsExpectedDesc
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },

    async removeProject(index, project) {
      this.selectedProject.project.projects.splice(index, 1);

      const isMainProject = project.main;
      if (isMainProject && this.selectedProject.project.projects.length > 0) {
        const indexProjectWithId = this.selectedProject.project.projects.findIndex(project => project?.projectId);

        if (indexProjectWithId >= 0) {
          const id = this.selectedProject.project.projects[indexProjectWithId].id;

          await this.setMainProject(id, this.selectedProject.project.projects[indexProjectWithId]);
        } else {
          const id = this.selectedProject.project.projects[0].id;
          if (Number.isInteger(id)) {
            await this.setMainProject(id, this.selectedProject.project.projects[0]);
          } else {
            this.selectedProject.project.projects[0].main = true;
          }
        }
      }

      if (project?.id) {
        await this.$apollo
          .mutate({
            mutation: addOrRemoveProjectMutation,
            variables: {
              programProject: {
                id: project.id,
                program: { id: this.clientSchedule.id },
                project: { id: project.projectId }
              }
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      }

      await this.updateProgramProjectsOrder();
    },

    addProject() {
      const newProject = {
        id: null,
        name: "",
        part: null,
        projectFilter: "",
        searchedProjects: [],
        projectSearchLoader: false,
        main: false
      };
      if (this.selectedProject.project.projects.length <= 0) {
        newProject.main = true;
      }
      this.selectedProject.project.projects.push(newProject);
    },

    partDescription(items, projectId, partId) {
      const project = items?.find(a => a.id === projectId);
      const part = project?.parts?.find(part => part.id === partId);
      return part?.description;
    },

    changeProjectHandler(project, value) {
      if (!value) return;

      const mergedProjects = this.availableProjects.concat(this.availableCategoryProjects);
      const selectedProject = mergedProjects.find(project => project.keyCode === value);

      project.projectId = selectedProject.id;
      project.projectFilter = selectedProject.keyCode;
      project.label = selectedProject.label;
      project.name = selectedProject.keyCode;
      project.parts = selectedProject.parts;
      project.allowance = selectedProject.allowance;

      this.onProjectNameChange(project);
      this.projectToSearch = "";
    },

    async updateProgramProjectsOrder() {
      await this.$apollo
        .mutate({
          mutation: changeProgramProjectsOrderMutation,
          variables: {
            programId: this.clientSchedule.id,
            projectIds: this.selectedProject.project.projects.filter(p => p.projectId).map(p => p.projectId)
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },

    searchProjectText(text, isCategory) {
      const array = isCategory ? this.availableCategoryProjects : this.availableProjects;
      return array?.filter(p => {
        return p.label.toLowerCase().includes(text?.toLowerCase()) || p.keyCode.toLowerCase().includes(text?.toLowerCase());
      });
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeMenuList === "projects") {
          return this.$emit("fetch-more-projects");
        } else {
          return this.$emit("fetch-more-categories");
        }
      }
    },

    projectFilterHandler: debounce(function(e) {
      if (this.activeMenuList === "projects") return this.$emit("filter-projects", e);

      return this.$emit("filter-project-categories", e);
    }, 850)
  },

  computed: {
    icons: () => ({
      mdiChevronDown,
      mdiChevronUp,
      mdiPlus,
      mdiMinus
    }),

    translations: () => translations,

    projectNames() {
      if (this.selectedProject.project.projects.length === 0) return [];

      let projects = this.selectedProject.project.projects
        .filter(p => p.name !== "")
        .map(project => ({
          id: project.id,
          name: project.name + (getShowProjectDescriptionInSchedule() ? ' ' + project.label : '')
        }));

      if (getShowProjectDescriptionInSchedule() && projects.length > 1) {
        let mainProjectId = this.selectedProject.project.projects.findIndex(p => p.main)
        return [projects[mainProjectId], {id: 0, name: '...'}]
      }

      return projects;
    },

    fontStyle() {
      return getUseBiggerFontInSchedule() ? 'font-size: 15px; font-weight: bold' : 'font-size: 12px'
    }
  }
};
</script>

<style>
.label-style {
  font-size: 13px;
}

.hover {
  cursor: pointer;
}

.program-card {
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.project-schedule .v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
}

.project-schedule .v-label {
  font-size: 10px;
}

.schedule-border {
  border: 1px solid #bab7b7;
  border-radius: 4px;
}

.project-schedule .v-textarea textarea[readonly="readonly"] {
  color: #a6a6a6;
}
.project-schedule:not(.v-select).v-text-field input[readonly="readonly"] {
  color: #a6a6a6;
}

.project-schedule .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.project-schedule .sg-select {
  font-size: 12px;
  width: 100%;
  max-width: 40px;
}

.project-schedule .sg-select:hover .v-input__slot {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px !important;
}

.project-schedule .sg-select .v-input__slot {
  padding: 0 0 0 2px !important;
  margin: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 0 !important;
}

.project-schedule .sg-select .v-input__append-inner {
  display: none;
}

.project-schedule .sg-select .v-select__selection--comma {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.project-schedule .sg-select .v-input__icon--append {
  width: 10px !important;
  height: 10px !important;
}

.project-schedule textarea {
  font-size: 10px !important;
  line-height: 16px !important;
  resize: none !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-radius: 5px;
  padding-left: 2px;
}

.project-schedule .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

div.project-autocomplete > div.v-input__control > div.v-input__slot > div.v-select__slot > div.v-input__append-inner {
  margin-top: 4px !important;
}

div.project-autocomplete > div.v-input__control > div.v-input__slot > div.v-select__slot > div.v-input__append-inner > div.v-input__icon > button {
  font-size: 18px !important;
}
</style>
